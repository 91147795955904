import React from "react";

import { useMediaQuery } from "react-responsive";

import "../../styles/about.css";
import AboutCard from "./AboutCard";

import beam from "../../icons/beam.png";
import brickwork from "../../icons/brickwork.png";
import drainage from "../../icons/drainage.png";
import fillColor from "../../icons/fill-color.png";
import jackhammer from "../../icons/jackhammer.png";
import temperature from "../../icons/temperature.png";

function About() {
  const isMobile = useMediaQuery({ query: "(max-width: 764px)" });
  const mobileScreen = isMobile ? "column" : "row";
  const mobileScreenBottomMargin = isMobile ? 60 : 10;
  const mobileAlignment = isMobile ? "center" : "left";
  const bottomMarginText = isMobile ? 40 : 10;
  return (
    <section
      className="about__simplicity simplicity"
      style={{
        display: "flex",
        justifyConent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: mobileScreenBottomMargin,
      }}
    >
      <div
        id="about"
        className="container"
        style={{
          margin: "0 auto",
          paddingLeft: "50px",
          paddingRight: "50px",
          flexDirection: "row",
          marginTop: "100px",
          marginBottom: "100px",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          marginLeft: "0px",
          marginRight: "0px",
          flexShrink: 1,
        }}
      >
        <div className="row">
          <div style={{ width: "100%", flex: 1 }}>
            <h2
              className="simplicity__heading heading"
              style={{ fontSize: "2.75" }}
            >
              Über uns
            </h2>
            <div
              className="simplicity__subheading"
              style={{
                color: "#F6C506",
                textAlign: mobileAlignment,
                marginBottom: bottomMarginText,
              }}
            >
              „Qualität ist das, was wir verfolgen“
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: mobileScreen,
                justifyContent: "center",
                alignItems: "center",
                margin: "10px",
                marginRight: "0px",
              }}
            >
              <div
                style={{
                  paddingBottom: "0px",
                  paddingRight: "10px",
                  flex: 1,
                  lineHeight: "2",
                  color: "#999",
                  fontSize: "19px",
                  marginBottom: "60px",
                }}
              >
                Die HOXHAJ Bauunternehmen GmbH wurde im Jahr 2018 gegründet.
                Geführt wird das Unternehmen von unserem Gesellschafter und
                Geschäftsführer Herr Urim Hoxhaj. <br />
                <br />
                Durch seine Jahrzehnte lange Berufserfahrung im Baugewerbe
                verfügt er über weitreichende Kenntnisse im bautechnischen – als
                auch im kaufmännischen Bereich. Tatkräftig wird er von unserem
                Technischen Betriebsleiter und Maurermeister Herr Adolf
                Vettermann unterstützt. Dieser ist in unserem Unternehmen
                verantwortlich für Kalkulationen, Angebote und
                Baustellencontrolling. <br />
                <br />
                Wir sind an einer langfristigen und erfolgsorientierten
                Zusammenarbeit mit gemeinschaftlichen Zielen interessiert.
              </div>

              <div
                className="aboutCardsContainer"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  flex: "1 1",
                  padding: "0px",
                  margin: "0px",
                  width: "auto",
                  // backgroundColor: "yellow",
                  justifyContent: "center",
                  marginBottom: 10,
                }}
              >
                <AboutCard text="Betonarbeiten" img={jackhammer} />
                <AboutCard text="Mauerarbeiten" img={brickwork} />

                <AboutCard text="Betonstahlarbeiten" img={beam} />
                <AboutCard text="Abdichtungsarbeiten" img={fillColor} />

                <AboutCard text="Dämmarbeiten" img={temperature} />
                <AboutCard text="Entwässerungs kanalarbeiten" img={drainage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
