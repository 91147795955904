import React, { useEffect, useRef, useState } from "react";

import "../styles/bootstrap.min.css";
import "../styles/main.css";
import "../styles/theme-dark.css";
import "../styles/colors.css";
import "../styles/Added.css";
import "../styles/sidenavAnimation.css";
import "../styles/mobileStyle/all.css";

import SvgsProject from "../components/SvgsProject";
import SideNavigation from "../components/SideNavigation";
import StartSection from "../components/StartSection";
import About from "../components/About/About";
import Projects from "../components/Projects/Projects";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import CompanyApproach from "../components/CompanyApproach";
import ParallaxOverflow from "../components/ParallaxOverflow";

import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

const IndexPage = () => {
  const [displayNav, setstate] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 764px)" });

  useEffect(() => {
    // check if parallaxoverflow is visible, then set state to hide sidenav
    // shows navbar when no parallaxoverflow is in user sight
    const callback = (entries, obs) => {
      let navVisible = true;
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        switch (entry.target.id) {
          case "doubleParallax":
            navVisible = false;
            break;
          default:
            navVisible = true;
        }
      });

      setstate(navVisible);
    };

    const options = {
      threshold: 0.8,
    };
    const observer = new IntersectionObserver(callback, options);
    const parallaxStart = document.querySelector("#doubleParallax");
    // if parallaxOverflow is not found then show sidenav (could be when user refreshes site and not on start)
    if (!parallaxStart) setstate(true);
    else observer.observe(parallaxStart);
  }, []);

  const cookieRef = useRef();

  const isCookieActive = getCookieConsentValue("hoxhaj") ? true : false;
  const [isFormEnabled, setIsFormEnabled] = useState(isCookieActive);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hoxhaj Bauunternehmen GmbH</title>
      </Helmet>{" "}
      {!isMobile && (
        <div style={{ width: "100%" }}>
          <ParallaxOverflow />
        </div>
      )}
      <div className="webpage" id="start">
        <SvgsProject />

        <div
          className="layout layout--left-aside"
          style={{ backgroundColor: "black" }}
        >
          <div
            style={{
              backgroundColor: "red",
              display: displayNav ? "block" : "none",
            }}
            className="sideNavigation"
          >
            <SideNavigation />
          </div>

          <main id="mainContent">
            <article className="about about--pb">
              <div className="wrappertop">
                <StartSection />
              </div>

              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
                  className="shape-fill"
                ></path>
              </svg>

              <About />

              <svg
                style={{
                  transform: "rotateY(180deg)",
                  backgroundColor: "black",
                }}
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  fill="#181818"
                  d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
                  className="shape-fill"
                ></path>
              </svg>
              <Projects />

              <svg
                style={{ backgroundColor: "#232323" }}
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
                  className="shape-fill"
                ></path>
              </svg>

              <CompanyApproach />

              <svg
                style={{
                  transform: "rotateY(180deg)",
                  backgroundColor: "#090707",
                }}
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  style={{ backgroundColor: "red" }}
                  fill="#232323"
                  d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
                  className="shape-fill"
                ></path>
              </svg>

              <Contact isFormEnabled={isCookieActive} />

              <Footer />

              <CookieConsent
                ref={cookieRef}
                location="bottom"
                buttonText="Akzeptieren"
                declineButtonText="Ablehnen"
                enableDeclineButton
                hideOnDecline={false}
                cookieName="hoxhaj"
                expires={150}
                style={{
                  zIndex: 2,
                  padding: 5,
                  fontSize: 15,
                  alignItems: "center",
                }}
                onDecline={() => setIsFormEnabled(false)}
                onAccept={() => setIsFormEnabled(true)}
              >
                Damit wir unsere Webseiten für Sie optimieren und
                personalisieren können würden wir gerne Cookies verwenden. Zudem
                werden Cookies gebraucht, um Funktionen von Soziale Media
                Plattformen anbieten zu können, Zugriffe auf unsere Webseiten zu
                analysieren und Informationen zur Verwendung unserer Webseiten
                an unsere Partner in den Bereichen der sozialen Medien, Anzeigen
                und Analysen weiterzugeben. Sind Sie widerruflich mit der
                Nutzung von Cookies auf unseren Webseiten einverstanden?
              </CookieConsent>
            </article>
          </main>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
